import { Temporal } from '@js-temporal/polyfill';
import { ILOS, ILineItem, IPricing, PricingOrGap } from '../../../types/pricing';
import { dayOfWeek, getLocalTemporalDate } from './date-helpers';
import { anyCheckInAllowed, isPricingGap, periodAllowsStayOfLength, periodsAndDaysPerPeriod, fromFloyd } from './pricing-helpers';
import { IDirectDiscount, IRestriction } from 'types/content';
import { calculatePricePerNight, getCurrencySymbol } from 'state/atoms/pricing';
import { calculateDiscount } from './discount';
import { getStayPrice } from 'lib/los';

const CANNOT_STAY_PRICE = 0;

/**
 * Adjusts a subStay price to take into account any discounts on the pricing period.
 * Applies the discount with the highest number of nights which applies to this booking.
 * Mirrors the python code here: https://github.com/TravelNest/pricing/blob/0725e1060aae7cc4d2930c59d023f393a8fa9686/models/pricing_models/period/pricing_period.py#L436
 *
 * @param pricingPeriod {IPricing} - Pricing period linked to the subStay.
 * @param subStayPrice  {number} - The pre-discount price of this subStay.
 * @param totalBookingNights {number} - Total number of nights of the entire stay (not just this subStay).
 *
 * @returns {number} - The adjusted subStay price
 */
export const applyDiscountToSubStay = (pricingPeriod: IPricing, subStayPrice: number, totalBookingNights: number) => {
    if (!pricingPeriod.discounts) return subStayPrice;
    const sortedDiscountsDesc = [...pricingPeriod.discounts].sort((a, b) => b.nights - a.nights);
    const discountToApply = sortedDiscountsDesc.find(discount => totalBookingNights >= discount.nights);
    if (!discountToApply) return subStayPrice;
    return discountToApply.factor * subStayPrice;
}

/**
 * Calculate the stay price for a given stay starting on a check-in date and for a number of nights.
 *
 * **Note** that the input pricing array should start at a period/gap including the check-in date, cover
 * the entire stay, and be sorted by date.
 *
 * @param checkIn {Date} - The starting day of the stay
 * @param nights  {number} - The number of nights the stay will comprise.
 * @param pricing {PricingOrGap[]} - An array of pricing periods padded with PricingGap objects where gaps exist.
 *
 * @returns {number} - A positive integer of the price of the stay, where 0 indicates that stay is not allowed.
 */
export const calculateStayPrice = (checkIn: string, nights: number, pricing: PricingOrGap[]): number => {
    const periodsAndSubStays = periodsAndDaysPerPeriod(checkIn, nights, pricing);
    const checkInDayOfWeek = dayOfWeek(checkIn);

    const gaps = periodsAndSubStays.filter((pps) => isPricingGap(pps[1]));
    if (gaps.length) {
        return CANNOT_STAY_PRICE;
    }

    const firstSubStay = periodsAndSubStays[0];

    const firstSubStayAllowed = periodAllowsStayOfLength(checkInDayOfWeek, nights, firstSubStay[1] as IPricing);
    if (!firstSubStayAllowed) {
        return CANNOT_STAY_PRICE;
    }

    const totalStayPrice = periodsAndSubStays.reduce((total: number, curr: [number, IPricing], idx: number) => {
        const [subStayNumNights, period] = curr;
        const dow = idx === 0 ? checkInDayOfWeek : dayOfWeek(period.start_date);

        const perNightPrice =
            subStayNumNights > 7
                ? period.prices.los[dow][6] / 7
                : period.prices.los[dow][subStayNumNights - 1] / subStayNumNights;

        const preDiscountSubStayPrice = perNightPrice * subStayNumNights;
        const discountedSubStayPrice = applyDiscountToSubStay(period, preDiscountSubStayPrice, nights);

        return total + discountedSubStayPrice;
    }, 0);

    return Math.round(totalStayPrice);
};

/**
 * Gather all the mapping functions together for calculating the stay price and return a single value
 * @param checkInDate {string} - the date the guest wishes to check in
 * @param checkOutDate {string} - the date the guest wishes to check out
 * @param losPricing {IPricing} - an array of IPricing object which include los_availability and los pricing
 * @returns {number} a normalized (non-floydian) value for the stay price
 */
export const buildStayPrice = (checkInDate: string, checkOutDate: string, losPricing: IPricing[]) => {

    const startingPeriodIdx = losPricing.findIndex((pp) => (
        Temporal.PlainDate.compare(checkInDate, pp.start_date) >= 0 &&
        Temporal.PlainDate.compare(checkInDate, pp.end_date) <= 0
    ));

    if (startingPeriodIdx < 0) {
        return CANNOT_STAY_PRICE;
    }

    const noPastPricing = losPricing.slice(startingPeriodIdx);

    if (!anyCheckInAllowed(checkInDate, noPastPricing[0])) {
        return CANNOT_STAY_PRICE;
    }
    const nights = Temporal.PlainDate.from(checkInDate).until(checkOutDate).days;
    const constrainedPricing = noPastPricing.filter((pp) => Temporal.PlainDate.compare(pp.start_date, checkOutDate) <= 0);

    const stayPrice = calculateStayPrice(checkInDate, nights, constrainedPricing);

    return fromFloyd(stayPrice);
}

interface ICalculatedPricing {
    isDateSelected: boolean,
    hasAvailability: boolean,
    currency: string,
    baseStayPrice: number,
    totalBeforeDiscount: number,
    totalToPayToday: number,
    minStay: number,
    lineItems: ILineItem[],
    error?: string
}

export const calculatePricing = (
    los: ILOS,
    extraFees: IRestriction[],
    discount: IDirectDiscount | null | undefined,
    currency: string,
    startDate: Date | null,
    endDate: Date | null,
): ICalculatedPricing => {
    if (!startDate || !endDate) {
        return {
            isDateSelected: false,
            hasAvailability: false,
            currency,
            baseStayPrice: 0,
            totalBeforeDiscount: 0,
            totalToPayToday: 0,
            minStay: 1,
            lineItems: [],
        }
    }

    const startDateISO8601 = getLocalTemporalDate(startDate).toString();
    const endDateISO8601 = getLocalTemporalDate(endDate).toString();

    // Get the base stay price
    const baseStayPrice = getStayPrice(startDateISO8601, endDateISO8601, 1, los);
    if ('error' in baseStayPrice) {
        console.log('returning pricing error', baseStayPrice.error)
        return {
            isDateSelected: true,
            hasAvailability: false,
            currency,
            baseStayPrice: 0,
            totalBeforeDiscount: 0,
            totalToPayToday: 0,
            minStay: 0,
            lineItems: [],
            error: baseStayPrice.error
        };
    }

    // if the stay is entirely unpriceable, return early with 0 values
    const lineItems: ILineItem[] = [];
    const currencySymbol = getCurrencySymbol(currency)

    // add a line item for the average nightly cost
    const [nightlyCost, numOfNights] = calculatePricePerNight(startDateISO8601, endDateISO8601, baseStayPrice.stayPrice);
    lineItems.push({
        timeOfCollection: 'BOOKING',
        amount: baseStayPrice.stayPrice,
        description: `${currencySymbol}${Math.ceil(nightlyCost)} x ${numOfNights} nights`
    });

    // add a line item for any additional fees
    extraFees
        .filter((fee) => fee.allowed && fee.charge > 0)
        .forEach((fee) => {
            lineItems.push({
                timeOfCollection: 'BOOKING',
                amount: fee.charge,
                description: `${fee.restriction_type.charAt(0).toUpperCase() + fee.restriction_type.slice(1)}`
            })
        });

    // add a line item for any discounts
    if (discount) {
        const discountLineItem = calculateDiscount(baseStayPrice.stayPrice, currency, discount)
        if (discountLineItem) {
            lineItems.push(discountLineItem);
        }
    }

    // calculate the total to pay (before discounts)
    const totalBeforeDiscount = lineItems.reduce((sum, item) =>
        item.timeOfCollection === 'BOOKING' && item.amount > 0 ? sum + item.amount : sum,
        0
    );

    // calculate the total to pay
    const totalToPayToday = lineItems.reduce((sum, item) =>
        item.timeOfCollection === 'BOOKING' ? sum + item.amount : sum,
        0
    );

    // The stay is technically pricable, even if the applied discount makes it a negative cost
    // which will be handled by the booking form
    console.log('returning pricing')
    return {
        isDateSelected: true,
        hasAvailability: true,
        currency,
        baseStayPrice: baseStayPrice.stayPrice,
        totalBeforeDiscount: totalBeforeDiscount,
        totalToPayToday: totalToPayToday,
        minStay: baseStayPrice.minNights,
        lineItems
    };
}
